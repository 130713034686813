// index
export const INDEX_SET_SUCCESS = 'setNotifySuccess'
export const INDEX_SET_ERROR = 'setNotifyError'
export const INDEX_SET_WARNING = 'setNotifyWarning'
export const INDEX_SET_LOADING = 'setLoading'
export const INDEX_SET_HIDE_FOOTER = 'setHideFooter'
export const MASTER_GET_DATA = 'getMasterData'
export const UPLOAD_FILE = 'uploadFile'
export const REGISTER_RECEIVE_EMAIL = 'registerReceiveEmail'
export const GET_BANNER = 'getBanner'
export const GET_SETTING = 'getSetting'
export const GET_FLASH_SALE = 'getFlashSale'
// Filter
export const SET_REMOVE_FILTER = 'filter/setRemoveFilter'
export const SET_ADD_FILTER = 'filter/setAddFilter'
export const SET_CLEAR_ALL_FILTER = 'filter/setClearAllFilter'

// authentication
export const AUTH_LOGOUT = 'authentication/logout'
export const AUTH_SET_ROLES = 'authentication/setRoles'
export const AUTH_SET_PERMISSIONS = 'authentication/setPermissions'
export const AUTH_SEND_OTP = 'authentication/sendOtp'
export const AUTH_RE_SEND_OTP = 'authentication/reSendOtp'
export const AUTH_VERIFY_OTP = 'authentication/verifyOtp'
export const AUTH_REGISTER = 'authentication/register'
export const AUTH_FORGOT_PASSWORD = 'authentication/forgotPassword'
export const AUTH_FORGOT_PASSWORD_VERIFY = 'authentication/forgotPasswordVerifyOtp'
export const AUTH_RESET_PASSWORD = 'authentication/resetPassword'
export const AUTH_OPEN_MODAL_LOGIN = 'authentication/setOpenModalLoginState'
export const AUTH_SET_RESET_CART = 'authentication/setResetCartState'

// category
export const CATEGORY_OUT_STANDING = 'category/getOutStanding'
export const CATEGORY_GET_DETAIL = 'category/getDetail'
export const CATEGORY_GET_LIST_PRODUCT = 'category/getListProduct'
export const CATEGORY_SEARCH = 'category/categorySearch'

// user
export const USER_UPDATE_PROFILE = 'user/updateProfile'
export const USER_UPLOAD_AVATAR = 'user/uploadAvatar'

// product
export const PRODUCT_DETAIL = 'product/detail'
export const PRODUCT_COMMENT = 'product/comment'
export const PRODUCT_COMMENT_IMAGE = 'product/commentImage'
export const PRODUCT_FAVORITE_LIST = 'product/favoriteList'
export const PRODUCT_OLD_LIST = 'product/oldList'
export const PRODUCT_FAVORITE = 'product/like'
export const PRODUCT_RELATED = 'product/related'
export const PRODUCT_DAILY_LIST = 'product/getProductDaily'
export const PRODUCT_LAST_SEEN = 'product/getProductLastSeen'
export const PRODUCT_SEARCH = 'product/productSearch'
export const SELLING_PRODUCT_LIST = 'product/getSellingProduct'
export const DEAL_HOT_LIST = 'product/getDealHot'
// cart
export const CART_ADD_CART = 'cart/addCard'
export const CART_GET_CART = 'cart/getCart'
export const CART_REMOVE_CART = 'cart/removeCart'
export const CART_SET_CART = 'cart/setCart'
export const CART_COUNT = 'cart/cartCount'
export const CART_SET_COUNT = 'cart/setCount'
export const CART_CHECKOUT_DATA = 'cart/setCheckoutData'

// shop
export const SHOP_GET_LIST_HOT = 'shop/getListStoreHot'
export const SHOP_GET_DETAIL = 'shop/getStoreDetail'
export const SHOP_GET_LIST_CATEGORY = 'shop/getListCategoryByStore'
export const SHOP_GET_LIST_PRODUCT = 'shop/getListProductByStore'
export const SHOP_FOLLOW_SHOP = 'shop/followShop'

// order
export const ORDER_CREATE_ORDER = 'order/saveOrder'
export const ORDER_GET_DRAFT = 'order/getDraft'
export const ORDER_UPDATE_ORDER = 'order/updateOrder'
export const ORDER_LIST_ORDER = 'order/listOrder'
export const ORDER_SAVE_EVALUATE = 'order/saveEvaluate'
export const ORDER_GET_EVALUATE = 'order/getEvaluate'

// quick order
export const QUICK_ORDER_CREATE_ORDER = 'quickOrder/createOrder'
export const QUICK_ORDER_ORDER_INFO = 'quickOrder/getOrderInfo'
export const QUICK_ORDER_UPDATE_ORDER = 'quickOrder/updateOrder'

// chat
export const CHAT_FETCH_MESSAGE = 'chat/fetchMessage'
export const CHAT_SEND_MESSAGE = 'chat/sendMessage'
export const CHAT_FETCH_ROOMS = 'chat/fetchRooms'
export const CHAT_FETCH_UN_READ_MESSAGE = 'chat/fetchUnreadMessage'
export const CHAT_SET_CHAT_WITH_SHOP = 'chat/setChatWithShop'
export const CHAT_SET_SWITCH_CHAT = 'chat/setSwitchChat'
export const CHAT_SET_UN_READ_MESSAGE = 'chat/setUnreadMessage'

// blog
export const GET_LIST_CATEGORY_BLOGS = 'blog/getListCategoryBlogs'
export const GET_LIST_BLOGS = 'blog/getListBlogs'
export const GET_LIST_BLOGS_TAG = 'blog/getListBlogsTag'
export const BLOG_DETAIL = 'blog/detailBlog'
export const PRODUCTS_RECOMMEND = 'blog/productsRecommend'
export const BLOGS_RANDOM = 'blog/randomBlog'
export const TYPICAL_BLOGS_LIST = 'blog/getTypicalBlog'

// showroom
export const GET_LIST_SHOWROOMS = 'showroom/getListShowrooms'

// agency
export const POST_CREATE_AGENCY = 'agency/registerAgency'
export const GET_LIST_AGENCY = 'agency/getListAgency'
