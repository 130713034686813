import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25defe4e = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _6317886b = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _35e934e8 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _28fc4580 = () => interopDefault(import('../pages/chinh-sach-bao-hanh.vue' /* webpackChunkName: "pages/chinh-sach-bao-hanh" */))
const _9f650968 = () => interopDefault(import('../pages/chinh-sach-van-chuyen.vue' /* webpackChunkName: "pages/chinh-sach-van-chuyen" */))
const _70701dbe = () => interopDefault(import('../pages/dang-ky-dai-ly.vue' /* webpackChunkName: "pages/dang-ky-dai-ly" */))
const _425eea1b = () => interopDefault(import('../pages/dieu-khoan-su-dung-chinh-sach-bao-mat.vue' /* webpackChunkName: "pages/dieu-khoan-su-dung-chinh-sach-bao-mat" */))
const _016b931e = () => interopDefault(import('../pages/error_page.vue' /* webpackChunkName: "pages/error_page" */))
const _f92973c2 = () => interopDefault(import('../pages/gioi-thieu.vue' /* webpackChunkName: "pages/gioi-thieu" */))
const _49a8779d = () => interopDefault(import('../pages/ho-tro-khach-hang.vue' /* webpackChunkName: "pages/ho-tro-khach-hang" */))
const _6b766701 = () => interopDefault(import('../pages/huong-dan-dat-hang.vue' /* webpackChunkName: "pages/huong-dan-dat-hang" */))
const _9d225a8c = () => interopDefault(import('../pages/huong-dan-tao-tai-khoan.vue' /* webpackChunkName: "pages/huong-dan-tao-tai-khoan" */))
const _d4554432 = () => interopDefault(import('../pages/huong-dan-thanh-toan.vue' /* webpackChunkName: "pages/huong-dan-thanh-toan" */))
const _b67cfeb6 = () => interopDefault(import('../pages/my_account.vue' /* webpackChunkName: "pages/my_account" */))
const _34d2ffdb = () => interopDefault(import('../pages/not_found.vue' /* webpackChunkName: "pages/not_found" */))
const _1975b620 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _ca69e39a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0b123523 = () => interopDefault(import('../pages/showroom.vue' /* webpackChunkName: "pages/showroom" */))
const _68db0308 = () => interopDefault(import('../pages/thong-tin-lien-he.vue' /* webpackChunkName: "pages/thong-tin-lien-he" */))
const _73509b71 = () => interopDefault(import('../pages/tra-hang-hoan-tien.vue' /* webpackChunkName: "pages/tra-hang-hoan-tien" */))
const _60be73b1 = () => interopDefault(import('../pages/blogs/search.vue' /* webpackChunkName: "pages/blogs/search" */))
const _af69bf24 = () => interopDefault(import('../pages/category/bo-suu-tap-thu-dong-2023.vue' /* webpackChunkName: "pages/category/bo-suu-tap-thu-dong-2023" */))
const _ac1e34f2 = () => interopDefault(import('../pages/product/favorite.vue' /* webpackChunkName: "pages/product/favorite" */))
const _19b453e8 = () => interopDefault(import('../pages/product/old.vue' /* webpackChunkName: "pages/product/old" */))
const _74b4e3d2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a63d39a0 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _294c40de = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _39567778 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _33662664 = () => interopDefault(import('../pages/checkout/_code.vue' /* webpackChunkName: "pages/checkout/_code" */))
const _c0b8cfc2 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _28bb0177 = () => interopDefault(import('../pages/san-pham/_slug.vue' /* webpackChunkName: "pages/san-pham/_slug" */))
const _21fa8f61 = () => interopDefault(import('../pages/store/_slug.vue' /* webpackChunkName: "pages/store/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _25defe4e,
    name: "blogs"
  }, {
    path: "/cart",
    component: _6317886b,
    name: "cart"
  }, {
    path: "/category",
    component: _35e934e8,
    name: "category"
  }, {
    path: "/chinh-sach-bao-hanh",
    component: _28fc4580,
    name: "chinh-sach-bao-hanh"
  }, {
    path: "/chinh-sach-van-chuyen",
    component: _9f650968,
    name: "chinh-sach-van-chuyen"
  }, {
    path: "/dang-ky-dai-ly",
    component: _70701dbe,
    name: "dang-ky-dai-ly"
  }, {
    path: "/dieu-khoan-su-dung-chinh-sach-bao-mat",
    component: _425eea1b,
    name: "dieu-khoan-su-dung-chinh-sach-bao-mat"
  }, {
    path: "/error_page",
    component: _016b931e,
    name: "error_page"
  }, {
    path: "/gioi-thieu",
    component: _f92973c2,
    name: "gioi-thieu"
  }, {
    path: "/ho-tro-khach-hang",
    component: _49a8779d,
    name: "ho-tro-khach-hang"
  }, {
    path: "/huong-dan-dat-hang",
    component: _6b766701,
    name: "huong-dan-dat-hang"
  }, {
    path: "/huong-dan-tao-tai-khoan",
    component: _9d225a8c,
    name: "huong-dan-tao-tai-khoan"
  }, {
    path: "/huong-dan-thanh-toan",
    component: _d4554432,
    name: "huong-dan-thanh-toan"
  }, {
    path: "/my_account",
    component: _b67cfeb6,
    name: "my_account"
  }, {
    path: "/not_found",
    component: _34d2ffdb,
    name: "not_found"
  }, {
    path: "/orders",
    component: _1975b620,
    name: "orders"
  }, {
    path: "/search",
    component: _ca69e39a,
    name: "search"
  }, {
    path: "/showroom",
    component: _0b123523,
    name: "showroom"
  }, {
    path: "/thong-tin-lien-he",
    component: _68db0308,
    name: "thong-tin-lien-he"
  }, {
    path: "/tra-hang-hoan-tien",
    component: _73509b71,
    name: "tra-hang-hoan-tien"
  }, {
    path: "/blogs/search",
    component: _60be73b1,
    name: "blogs-search"
  }, {
    path: "/category/bo-suu-tap-thu-dong-2023",
    component: _af69bf24,
    name: "category-bo-suu-tap-thu-dong-2023"
  }, {
    path: "/product/favorite",
    component: _ac1e34f2,
    name: "product-favorite"
  }, {
    path: "/product/old",
    component: _19b453e8,
    name: "product-old"
  }, {
    path: "/",
    component: _74b4e3d2,
    name: "index"
  }, {
    path: "/checkout/success/:id?",
    component: _a63d39a0,
    name: "checkout-success-id"
  }, {
    path: "/blogs/:slug",
    component: _294c40de,
    name: "blogs-slug"
  }, {
    path: "/category/:slug",
    component: _39567778,
    name: "category-slug"
  }, {
    path: "/checkout/:code?",
    component: _33662664,
    name: "checkout-code"
  }, {
    path: "/product/:id?",
    component: _c0b8cfc2,
    name: "product-id"
  }, {
    path: "/san-pham/:slug?",
    component: _28bb0177,
    name: "san-pham-slug"
  }, {
    path: "/store/:slug?",
    component: _21fa8f61,
    name: "store-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
